import { Durations } from '@/constants/durations'
import { paths } from '@/constants/paths'
import { slugs } from '@/constants/slugs'
import { Seo } from '@/layout/Seo'
import { BlogLayout, NextPageWithLayout } from '@/layouts'
import { BlogPost, getPosts } from '@/services/BlogService'
import { getGenericStaticPaths } from '@/utils/GetGenericStaticPaths'
import { getStaticPropsErrorHandler } from '@/utils/nextUtils/nextErrorHandlers'
import { getProjectTheme } from '@/utils/project-themes'
import { getBasePath } from '@/utils/sitemap/base'
import { loadTranslations } from '@/utils/translate/translate-server'
import BlogView from '@/views/BlogView'

interface BlogProps {
  locale: string
  posts: BlogPost[]
  project: {
    blogMetaDescription: string
    name: string
  }
}

const BlogPage: NextPageWithLayout<BlogProps> = ({ locale, posts, project }) => {
  const url = `${getBasePath(locale)}${paths.blog.index}`
  const title = 'Blog | Angel Studios'
  const openGraph = {
    title,
    description: project.blogMetaDescription,
    url,
    type: 'website',
  }
  return (
    <>
      <Seo canonical={url} openGraph={openGraph} title={title} description={project.blogMetaDescription} />
      <BlogView posts={posts} />
    </>
  )
}

BlogPage.getLayout = (page) => {
  return <BlogLayout isDarkMode={false}>{page}</BlogLayout>
}

export const getStaticProps = getStaticPropsErrorHandler(
  { filePath: 'pages/[locale]/blog/index.tsx' },
  async ({ locale, next404, preview }) => {
    const { name } = getProjectTheme(slugs.angelStudios)
    const { project, posts } = await getPosts({ preview })

    if (posts?.length === 0) {
      return next404(Durations.FIVE_MINUTES_IN_SECONDS)
    }

    return {
      props: {
        locale,
        posts,
        project: { ...project, name },
        ...(await loadTranslations(locale, ['common', 'account'])),
      },
      revalidate: Durations.FIFTEEN_MINUTES_IN_SECONDS,
    }
  },
)

export const getStaticPaths = getGenericStaticPaths

export default BlogPage
